import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { navigate } from "@reach/router";
import React, { useEffect, useRef, useState } from "react";
import { Field, reduxForm } from "redux-form";
import Swal from 'sweetalert2';
import settings from "../../../settings";
import { verifyPatent } from "../../../sevices/quoteService";
import CreditCardField from "../creditCardField";
import {
  renderCheckbox,
  renderDateField,
  renderNumberField,
  renderRadioGroup,
  renderSelectField,
  renderTextField,
  RenderTextFieldDisabled,
} from "../renderInputsForm";
import { useDispatch } from "react-redux";
import { showError } from "../../../actions/notificationActions";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 100,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 32
  },
  subtitle: {
    fontWeight: 100,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 20,
    marginTop: 25
  },
  button: {
    marginRight: theme.spacing(4),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    '& button': {
      marginTop: 8,
      marginBottom: 8
    }
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 0.15008
  },
  small: {
    fontWeight: 300,
    letterSpacing: 0.09,
    lineHeight: '19.2px',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.87)',
  }
}));

const StyledStepLabel = withStyles({
  label: {
    fontWeight: "100 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: 32,
  },
})(StepLabel);

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});


const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "name",
    "lastname",
    "documentNumber",
    "birthday",
    "gender",
    "email",
    "phone",
    "street",
    "streetNumber",
    "location",
    "cardOwnerDocumentType",
    "cardOwnerDocNumber",
    "cardOwnerName",
    "cardType",
    "cardNumber",
    "cardExpirationDate",
    "cardUsageConfirmation",
    "patentFormat",
    "patent",
    "vehicleColor",
    "vehicleChassisNumber",
    "vehicleEngineNumber"
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Requerido";
    }
  });

  /* if (
      values.peso &&
      (Number(values.peso) < 100 || Number(values.peso) > 350)
  ) {
      errors.peso = "Valor fuera de rango (min: 100, max: 350)";
  }

  if (
      values.estatura &&
      (Number(values.estatura) < 0.6 || Number(values.estatura) > 2.2)
  ) {
      errors.estatura = "Valor fuera de rango (min: 0.60, max: 2.20)";
  } */
  return errors;
};

const emailValidation = value => (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) || value === '') ?
  'Debe ser un mail válido' : undefined;

const phoneValidation = value => (!/^.[0-9]{9,12}$/g.test(value) || value === '') ? 'Debe ser un teléfono valido' : undefined;

const patentValidation = pattern => value => (!pattern.test(value) || value === '') ? 'Patrón inválido' : undefined;

const maxLength = max => value =>
  value && value.length > max ? `Deben ser ${max} caracteres o menos` : undefined;

const minLength = min => value =>
  value && value.length < min ? `Deben ser ${min} caracteres o más` : undefined;

const maxLength17 = maxLength(17);
const maxLength8 = maxLength(8);
const minLength14 = minLength(14);
const minLength6 = minLength(6);

const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Solo caracteres alfanuméricos'
    : undefined

const alphaNumericSymbols = value =>
  value && /[^a-zA-Z0-9-*]/i.test(value)
    ? 'Solo caracteres alfanuméricos o * y -'
    : undefined

const LibraClientInfoForm = ({
  handleSubmit,
  submitting,
  valid,
  onDataReturn,
  goToNextPage,
  quotationScreenValues,
  initialize,
  riskType
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [creditError, setCreditError] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState(null);
  const [creditCardExpiryDate, setCreditCardExpiryDate] = useState(null);
  const [creditCardCVC, setCreditCardCVC] = useState(null);
  const [selectedPattern, setSelectedPattern] = useState(new RegExp());
  const [documentTypes, setDocumentTypes] = useState([]);
  const [cardTypes, setCardTypes] = useState([]);
  const [cardType, setCardType] = useState('')
  const [colors, setColors] = useState([]);
  const [vehicleData, setVehicleData] = useState({
    patent: null,
    vehicleChassisNumber: null,
    vehicleEngineNumber: null
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    handleDocumentTypes();
    handleCardTypes();
    handleColors();
  }, []);

  useEffect(() => {
    if (
      quotationScreenValues.clientInfo &&
      quotationScreenValues.isFullfilledClientInfoForm
    ) {
      const _document = quotationScreenValues.clientInfo;
      /* setConditionalInputs(
          _document &&
          Object.keys(_document)
              .filter((k) => k.includes("_"))
              .map((k) => k.split("_")[0])
      ); */
      initialize({
        ..._document,
      });
      setVehicleData({
        patent: _document.patent,
        vehicleChassisNumber: _document.vehicleChassisNumber,
        vehicleEngineNumber: _document.vehicleEngineNumber
      });
    } else {
      initialize({
        email: quotationScreenValues.lead,
        phone: quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.telefono,
        location: `${quotationScreenValues?.quoteData?.Calle} (${quotationScreenValues?.quoteData?.CodPostal})`
      });
    }
  }, [quotationScreenValues]);


  const handleScroll = () => {
    const allTitles = Array.from(document.querySelectorAll('.client__vinculation-title'));
    const nextTitle = allTitles[activeStep];
    window.scrollTo({
      top: nextTitle.offsetTop - nextTitle.offsetHeight,
      behavior: 'smooth'
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleVerifyPatent = async () => {
    const isValid = selectedPattern.test(vehicleData.patent);
    if (isValid) {
      setLoading(true);
      const result = await verifyPatent(vehicleData.patent, vehicleData.vehicleChassisNumber, vehicleData.vehicleEngineNumber);
      setLoading(false);
      if (result) {
        Toast.fire({
          icon: 'error',
          title: 'Una póliza ya ha sido generada con esta patente, motor o chasis.'
        });
      } else {
        handleNext();
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Patente no sigue el formato seleccionado.'
      });
    }
  };

  const handleSubmits = async (values) => {
    const formattedValues = {
      ...values,
      vehicleColorDesc: colors.find(c => c.cod_color === values.vehicleColor)?.txt_desc,
      creditCardNumber,
      creditCardExpiryDate,
      creditCardCVC
    };


    if (!validateCardType(creditCardNumber, cardType)) {
      dispatch(showError('El número de tarjeta no coincide con el tipo seleccionado'))
      return
    }

    Swal.fire({
      title: '',
      html: 'Estamos trabajando en la generación de tu propuesta, por favor aguardá unos instantes mientras finaliza el proceso.',
      didOpen: () => {
        Swal.showLoading();
      },
      customClass: {
        container: 'modal__message',
        loader: 'modal__message-loader'
      }
    });
    await onDataReturn(formattedValues);
    Swal.hideLoading();
    Swal.close();
    goToNextPage();
  };

  const handleVehicleDataChange = (e) => {
    setVehicleData({
      ...vehicleData,
      [e.target.name]: e.target.value
    });
  };

  const handleCreditCardChange = (e, v) => {
    switch (e.target.name) {
      case 'cardNumber':
        setCreditCardNumber(e.target.value);
        break;
      case 'expiryDate':
        setCreditCardExpiryDate(e.target.value);
        break;
      case 'cvc':
        setCreditCardCVC(e.target.value);
        break;
    };
  };

  const handleDocumentTypes = async () => {
    const res = await fetch(`${settings.internalListsUrlApi}internalidentificationtypes`)
      .then(res => res.json());

    setDocumentTypes(res);
  };

  const handleCardTypes = async () => {
    const res = await fetch(`${settings.listsUrlApi}CardList`)
      .then(res => res.json());

    setCardTypes(res);
  };

  const handleColors = async () => {
    const res = await fetch(`${settings.listsUrlApi}colors`)
      .then(res => res.json());

    setColors(res?.colors?.result);
  };

  const handleSetSelectedPattern = (e) => {
    const value = e.target.value;

    const selected = getPatentOptionsByVehicleType(riskType).find(p => p.value === value);

    setSelectedPattern(selected.pattern);
  };

  const goBack = () => {
    navigate('proposal');
  }

  const handleChangeCardType = (e) => {
    const value = e.target.value;

    setCardType(value);
  }

  const genderOptions = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Femenino" },
    { value: "Sin especificar", label: "Sin especificar" },
  ];

  const getPatentOptionsByVehicleType = (type) => {
    if (type === 'AUTOMOTORES') {
      return [
        /* { value: "1234ABC", label: "1234ABC", pattern: /^\d{4}\w{3}$/ },
        { value: "123ABCD", label: "123ABCD", pattern: /^\d{3}\w{4}$/ },
        { value: "A123456", label: "A123456", pattern: /^\w{1}\d{6}$/ },
        { value: "A123B4", label: "A123B4", pattern: /^\w{1}\d{3}\w{1}\d{1}$/ },
        { value: "A12BC3D", label: "A12BC3D", pattern: /^\w{1}\d{2}\w{2}\d{1}\w{1}$/ },
        { value: "A12BCD", label: "A12BCD", pattern: /^\w{1}\d{2}\w{3}$/ },
        { value: "AAA1234", label: "AAA1234", pattern: /^\w{3}\d{4}$/ }, */
        { value: "AB123CD", label: "AB123CD", pattern: /^\w{2}\d{3}\w{2}$/ },
        // { value: "ABC12", label: "ABC12", pattern: /^\w{3}\d{2}$/ },
        { value: "ABC123", label: "ABC123", pattern: /^\w{3}\d{3}$/ },
        /* { value: "ABC123D", label: "ABC123D", pattern: /^\w{3}\d{3}\w{1}$/ },
        { value: "ABC1D23", label: "ABC1D23", pattern: /^\w{3}\d{1}\w{1}\d{2}$/ },
        { value: "ABCD12", label: "ABCD12", pattern: /^\w{4}\d{2}$/ },
        { value: "ABCD123", label: "ABCD123", pattern: /^\w{4}\d{3}$/ }, */
      ];
    } else {
      return [
        /* { value: "12345A", label: "12345A", pattern: /^\d{5}\w{1}$/ },
        { value: "1234A", label: "1234A", pattern: /^\d{4}\w{1}$/ },
        { value: "1234AB", label: "1234AB", pattern: /^\d{4}\w{2}$/ }, */
        { value: "123ABC", label: "123ABC", pattern: /^\d{3}\w{3}$/ },
        /* { value: "123ABCD", label: "123ABCD", pattern: /^\d{3}\w{4}$/ },
        { value: "12A3456", label: "12A3456", pattern: /^\d{2}\w{1}\d{4}$/ },
        { value: "12ABC3", label: "12ABC3", pattern: /^\d{2}\w{3}\d{1}$/ },
        { value: "A/D", label: "A/D", pattern: /^\w{1}\w{1}$/ },
        { value: "A12345", label: "A12345", pattern: /^\w{1}\d{5}$/ }, */
        { value: "A123BCD", label: "A123BCD", pattern: /^\w{1}\d{3}\w{3}$/ },
        /* { value: "AAA1234", label: "AAA1234", pattern: /^\w{3}\d{4}$/ },
        { value: "AAA1A11", label: "AAA1A11", pattern: /^\w{3}\d{1}\w{1}\d{2}$/ },
        { value: "AB123CD", label: "AB123CD", pattern: /^\w{2}\d{3}\w{2}$/ },
        { value: "AB1C23D", label: "AB1C23D", pattern: /^\w{2}\d{1}\w{1}\d{2}\w{1}$/ },
        { value: "ABC12", label: "ABC12", pattern: /^\w{3}\d{2}$/ },
        { value: "ABC123", label: "ABC123", pattern: /^\w{3}\d{3}$/ },
        { value: "ABC12D", label: "ABC12D", pattern: /^\w{3}\d{2}\w{1}$/ },
        { value: "ABCD12", label: "ABCD12", pattern: /^\w{4}\d{2}$/ }, */
      ];
    }
  }


  const validateCardType = (number, type) => {
    const cardPatterns = {
      '2': /^4[0-9]{15}$/,
      '3': /^5[1-5][0-9]{14}$/,
      '7': /^3[47][0-9]{13}$/,
      '15': /^6[0-9]{15}$/,
      '16': /^37[0-9]{14}$/,
      '8': /^4[0-9]{15}$/,
    };

    const patternToTest = cardPatterns[cardType] || ''
    if (type) {
      return patternToTest.test(String(number).replace(/\s+/g, ''));
    }
  };

  // const selectedPatentValidation = patentValidation(selectedPattern);
  return (
    <form onSubmit={handleSubmit(handleSubmits)}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StyledStepLabel className="client__vinculation-title">
            Datos para la póliza
          </StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="name"
                    label="Nombres"
                    component={renderTextField}
                    custom={{ tabIndex: 1 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="lastname"
                    label="Apellidos"
                    component={renderTextField}
                    custom={{ tabIndex: 2 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="documentNumber"
                    label="Número de documento"
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="birthday"
                    label="Fecha de nacimiento"
                    component={renderDateField}
                    custom={{ tabIndex: 4 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="gender"
                    label="Sexo"
                    component={renderSelectField}
                    custom={{ tabIndex: 5 }}
                  >
                    {genderOptions.map(
                      (j, i) => (
                        <MenuItem
                          key={`${j.value}__${i}`}
                          value={j.value}
                        >
                          {j.label}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="email"
                    label="Correo"
                    component={renderTextField}
                    custom={{ tabIndex: 6 }}
                    validate={[emailValidation]}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="phone"
                    label="Teléfono"
                    component={renderTextField}
                    custom={{ tabIndex: 7 }}
                    validate={[phoneValidation]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={goBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!valid}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StyledStepLabel className="client__vinculation-title">Ubicación</StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="street"
                    label="Calle"
                    component={renderTextField}
                    custom={{ tabIndex: 8 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="streetNumber"
                    label="Número"
                    type="number"
                    component={renderNumberField}
                    custom={{ tabIndex: 9 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="location"
                    label="Localidad"
                    component={RenderTextFieldDisabled}
                    custom={{ tabIndex: 10 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="department"
                    label="Piso y departamento"
                    component={renderTextField}
                    custom={{ tabIndex: 11 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!valid}
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StyledStepLabel className="client__vinculation-title">Datos del vehículo</StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="patentFormat"
                    label="Formato de patente"
                    component={renderSelectField}
                    custom={{ tabIndex: 14 }}
                    onChange={handleSetSelectedPattern}
                  >
                    {getPatentOptionsByVehicleType(riskType).map(
                      (j, i) => (
                        <MenuItem
                          key={`patent__${j.value}__${i}`}
                          value={j.value}
                        >
                          {j.label}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="patent"
                    label="Patente"
                    component={renderTextField}
                    custom={{ tabIndex: 14 }}
                    onChange={handleVehicleDataChange}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="vehicleColor"
                    label="Color"
                    component={renderSelectField}
                    custom={{ tabIndex: 15 }}
                  >
                    {colors.map(
                      (j, i) => (
                        <MenuItem
                          key={`${j.cod_color}__${i}`}
                          value={j.cod_color}
                        >
                          {j.txt_desc}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="vehicleChassisNumber"
                    label="Número de Chasis"
                    component={renderTextField}
                    custom={{ tabIndex: 16 }}
                    validate={[minLength14, maxLength17, alphaNumeric]}
                    onChange={handleVehicleDataChange}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="vehicleEngineNumber"
                    label="Número de motor"
                    component={renderTextField}
                    custom={{ tabIndex: 17 }}
                    validate={[minLength6, maxLength17, alphaNumericSymbols]}
                    onChange={handleVehicleDataChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerifyPatent}
                  className={classes.button}
                  disabled={!valid}
                >
                  {loading ? <CircularProgress color="inherit" size={24} /> : 'Siguiente'}
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StyledStepLabel className="client__vinculation-title">
            Datos de tarjeta de crédito
          </StyledStepLabel>
          <StepContent TransitionProps={{
            onExited: handleScroll,
            timeout: {
              enter: 250,
              exit: 250
            }
          }}>
            <Typography component="h6" className={classes.subtitle}>
              Completá la información como aparece en la tarjeta.
            </Typography>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="cardOwnerDocumentType"
                    label="Tipo de documento"
                    component={renderSelectField}
                    custom={{ tabIndex: 12 }}
                  >
                    {documentTypes.map((d, i) => (
                      <MenuItem key={`${d.IdentificationTypeID}__${i}`} value={d.IdentificationTypeID}>
                        {d.Description}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="cardOwnerDocNumber"
                    label="Documento"
                    component={renderTextField}
                    custom={{ tabIndex: 13 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="cardOwnerName"
                    label="Nombre del titular"
                    component={renderTextField}
                    custom={{ tabIndex: 14 }}
                  />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="cardType"
                    label="Tipo de tarjeta"
                    onChange={handleChangeCardType}
                    component={renderSelectField}
                    custom={{ tabIndex: 15 }}
                  >
                    {cardTypes.map(
                      (j, i) => (
                        <MenuItem
                          key={`${j.DuctCode}__${i}`}
                          value={j.DuctCode}
                        >
                          {j.Description}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <CreditCardField
                    cardType={cardType}
                    creditError={setCreditError}
                    creditCardNumber={creditCardNumber}
                    creditCardExpiryDate={creditCardExpiryDate}
                    creditCardCVC={creditCardCVC}
                    handleChange={handleCreditCardChange} />
                </Grid>
                <Grid item style={{ marginBottom: 20 }}>
                  <Field
                    name="cardUsageConfirmation"
                    label="Autorizo a que el primer pago de la póliza se realice via MercadoPago y el resto por débito automático de mi tarjeta de crédito."
                    component={renderCheckbox}
                    custom={{ tabIndex: 16 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                  variant="outlined"
                >
                  ATRÁS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={submitting || !valid || creditError !== undefined}
                  type="submit"
                >
                  Guardar
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </form>
  );
};

export default reduxForm({ form: "LibraClientInfoForm", validate })(
  LibraClientInfoForm
);
